/**
 * Part of starter project.
 *
 * @copyright  Copyright (C) 2021 __ORGANIZATION__.
 * @license    __LICENSE__
 */

import '@main';

const tabs = $('.c-publication').find('.nav-link');

tabs.each(function (i, e) {
  var tabTrigger = new bootstrap.Tab(e);

  e.addEventListener('mouseover', function (event) {
    event.preventDefault();
    tabTrigger.show();
  });
});

const cookies = document.cookie.split('; ');
const cookieName = 'show-adv';
const hasAdvCookie = cookies.find(function (v) {
  return v.includes('show-adv=');
});

const advElement = document.getElementById('advertisement-modal');

if (advElement) {
  const advModal = new bootstrap.Modal(advElement, {
    keyboard: false
  });

  advElement.addEventListener('hide.bs.modal', function () {
    const checkBox = document.getElementById('adv-check');

    if (checkBox.checked) {
      const oneDay = 24 * 60 * 60 * 1000;
      const time = new Date(Date.now() + oneDay).toUTCString();

      document.cookie = `${cookieName}=1;expires=${time};path=/`;
    }
  });

  if (!hasAdvCookie) {
    advModal.show();

    const id = parseInt(advElement.dataset.id);

    u.$http.post(u.route('ajax_adv_log'), { id: id })
      .then(function (response) {

      })
      .catch(function (e) {
        console.error(e);
      });
  }
}
